@import url('https://fonts.googleapis.com/css2?family=Archivo+Black&family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Orbitron:wght@400..900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {

   /* Buttons */
   button-gradient {
      @apply bg-gradient-to-r from-violet-700 to-pink-500 py-4 px-5 rounded-md font-medium text-white uppercase font-body cursor-pointer flex justify-center items-center 
      hover:from-pink-600 hover:to-orange-500 
      
   }

   button-white {
      @apply bg-white py-4 px-10 font-medium max-md:text-sm uppercase font-body cursor-pointer flex justify-center items-center mx-2 my-2 hover:bg-slate-100
      [clip-path:polygon(7%_0%,_100%_0%,_100%_30%,_100%_70%,_93%_100%,_30%_100%,_0_100%,_0%_30%)] 
   }

   button-pink {
      @apply bg-pink-500 py-4 px-10 rounded-full font-medium text-white uppercase font-body cursor-pointer mx-2 my-2 flex justify-center items-center hover:bg-pink-600
      
   }

   button-outline {
      @apply border-2 border-blue-900 py-2 px-10 rounded-md font-medium text-white uppercase font-body text-sm cursor-pointer flex justify-center items-center hover:bg-cyan-500 hover:bg-opacity-25
      max-md:px-5
   }

   button-clip {
      @apply bg-orange-500 py-4 px-10 font-medium text-white uppercase font-body cursor-pointer mx-2 my-2 flex justify-center items-center hover:bg-orange-600
      [clip-path:polygon(7%_0%,_100%_0%,_100%_30%,_100%_70%,_93%_100%,_30%_100%,_0_100%,_0%_30%)] 
   }


   /* Hero VDO */
   video {
      width:100%;
      height: 100%;
      object-fit: cover;
   }

   /* React Player */
   .player-wrapper {
      position: relative;
      padding-top: 56.25%; /* 720 / 1280 = 0.5625 */
    }
    
    .react-player {
      position: absolute;
      top: 0;
      left: 0;
    }
}



